import React, { useEffect } from "react";
import CallRecording from "../../assests/1on1vdocall.mp4";
import "./VideosPage.scss";
import { useLocation } from "react-router-dom";

const VideosPage = () => {
  const location = useLocation();
  const videoUrl = location?.state?.val.vdoUrl;
  return (
    <div
      style={{
        backgroundColor: "var(--black)",
        width: "100vw",
        height: "100vh",
      }}
    >
      <video
        src={videoUrl}
        autoPlay
        muted
        preload="auto"
        controls
        style={
          !location.state.val?.isPortrait
            ? {
                transform: "rotate(-90deg)",
                transformOrigin: "left top",
                background: "var(--black)",

                width: "100vh",
                height: "100vw",
                overflowX: "hidden",
                position: "absolute",
                left: 0,
                top: "100vh",
                bottom: 0,
              }
            : { width: "100%", height: "100vh" }
        }
      ></video>
    </div>
  );
};

export default VideosPage;
