import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../../User/UserDMpage/UserDM.scss";
import SendIcon from "@mui/icons-material/Send";
import DMOPENGIF from "../../assests/dmopengif.png";
import diamands from "../../assests/diamands.png";
import profile from "../Images/profile.jpg";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import axios from "axios";

function ModalSideUserHelpAndSupportDm() {
  const location = useLocation();
  const [lastDoc, setLastDoc] = useState(null);
  const [user, setUser] = useState(location.state.val);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInPutMessage] = useState("");
  const [UserDeviceToken, setUserDeviceToken] = useState();
  const [userInfo, setUserInfo] = useState({});
  const fileUpload = useRef();

  useEffect(() => {
    getData();
    getFcmTokenOfUser();
    window.scrollTo({ behavior: "smooth" });
  }, []);

  const getFcmTokenOfUser = () => {
    let q1 = query(
      collection(db, process.env.REACT_APP_DEVICETOKEN_COLLECTION),
      where("uid", "==", user.userId),
      orderBy("fcmTokenTime", "desc"),
      limit(1)
    );
    getDocs(q1).then((result) => {
      result.forEach((doc) => {
        setUserDeviceToken(doc.data().deviceToken);
      });
    });
  };

  const getData = (lastDoc = null) => {
    let q4;
    if (lastDoc) {
      q4 = query(
        collection(
          db,
          process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
          `${user.userId}`,
          process.env.REACT_APP_MESSAGES_COLLECTION
        ),
        orderBy("sentAt", "desc"),
        startAfter(lastDoc),
        limit(15)
      );
    } else {
      q4 = query(
        collection(
          db,
          process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
          `${user.userId}`,
          process.env.REACT_APP_MESSAGES_COLLECTION
        ),
        orderBy("sentAt", "desc"),
        limit(15)
      );
      let q1 = doc(
        db,
        process.env.REACT_APP_USERS_COLLECTION_NAME,
        location.state.val.userId
      );
      getDoc(q1).then((response) => {
        setUserInfo(response.data());
      });
    }

    onSnapshot(q4, (querySnapshot) => {
      let chats = [];
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      querySnapshot.forEach((doc) => {
        chats.push(doc.data());
      });
      setMessages([...messages, ...chats]);
    });
  };

  const handleSendMessages = (val) => {
    if (inputMessage.trim() !== "") {
      let isChat = val == "chat";
      const updateLastChatUnreadCount = doc(
        db,
        process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
        user.userId
      );
      let updateLastMessage = {
        totalUnreadCount: 0,
      };
      updateDoc(updateLastChatUnreadCount, updateLastMessage).then(() => {});
      let lastMessageFromModal = {
        chatMessage: inputMessage,
        isRead: true,
        noOfGems: parseInt(process.env.REACT_APP_NOOFGEMSFORHELPANDSUPPORT),
        platform: process.env.REACT_APP_PLATFORM,
        sentAt: Timestamp.fromDate(new Date()),
        sentBy: process.env.REACT_APP_DM_FROM_RECEIVER,
        version: process.env.REACT_APP_VERSION,
      };

      if (isChat) {
        setInPutMessage("");
        let newMessage = [...messages];
        newMessage.unshift(lastMessageFromModal);
        setMessages(newMessage);
        updateDoc(updateLastChatUnreadCount, {
          chatAlreadyDone: true,
          fcmDeviceToken: UserDeviceToken,
          lastMessageTime: serverTimestamp(),
          chatAlreadyDone: true,
          lastMessageFromUser:
            process.env.REACT_APP_LAST_MESSAGE_FROM_USER_MODAL_MESSAGE_TO_USER,
          lastChatMessage: inputMessage,
        }).then(() => {});
        addDoc(
          collection(
            db,
            process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
            user.userId,
            process.env.REACT_APP_MESSAGES_COLLECTION
          ),
          {
            ...lastMessageFromModal,
            sentAt: serverTimestamp(),
          }
        ).then(() => {});
      } else {
        const formData = new FormData();
        formData.append("file", val.target.files[0]);
        let axiosConfig = {
          headers: {
            "X-Auth-Email": process.env.REACT_APP_X_AUTH_EMAIL,
            "X-Auth-Key": process.env.REACT_APP_X_AUTH_KEY,
            Authorization: process.env.REACT_APP_AUTHORIZATION,
            "Content-Type": process.env.REACT_APP_CONTENT_TYPE,
            "Access-Control-Allow-Origin":
              process.env.REACT_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
            Accept: process.env.REACT_APP_ACCEPT,
          },
        };
        axios
          .post(
            process.env.REACT_APP_IMAGE_UPLOAD_CLOUDFAIR_URL,
            { file: val.target.files[0] },
            axiosConfig
          )
          .then((response) => {
            if (response.data.success) {
              let imgID = response.data.result.id;

              let imagePath = `${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL1}${imgID}${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL2}`;

              lastMessageFromModal = {
                ...lastMessageFromModal,
                chatMessage: imagePath,
              };
              setInPutMessage("");
              let newMessage = [...messages];
              newMessage.unshift(lastMessageFromModal);
              setMessages(newMessage);
              updateDoc(updateLastChatUnreadCount, {
                fcmDeviceToken: UserDeviceToken,
                lastMessageTime: serverTimestamp(),
                lastMessageFromUser:
                  process.env
                    .REACT_APP_LAST_MESSAGE_FROM_USER_MODAL_MESSAGE_TO_USER,
                lastChatMessage: inputMessage,
              }).then(() => {});
              addDoc(
                collection(
                  db,
                  process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
                  user.userId,
                  process.env.REACT_APP_MESSAGES_COLLECTION
                ),
                {
                  ...lastMessageFromModal,
                  sentAt: serverTimestamp(),
                }
              ).then(() => {});
            }
          });
      }

      let isunread = [];
      let q2 = query(
        collection(
          db,
          process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
          `${user.userId}`,
          process.env.REACT_APP_MESSAGES_COLLECTION
        ),
        where("isRead", "==", false)
      );
      getDocs(q2)
        .then((getusersUnreadmessages) => {
          getusersUnreadmessages.forEach((doc) => {
            isunread.push(doc.id);
          });
        })
        .then(() => {
          isunread.map((docID) => {
            updateDoc(
              doc(
                db,
                process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
                user.userId,
                process.env.REACT_APP_MESSAGES_COLLECTION,
                docID
              ),
              {
                isRead: true,
              }
            ).then(() => {});
          });
        });
    }
  };

  return (
    <Box className="userdmpage">
      <Box
        className="dm_head"
        style={{
          display: "flex",
          width: "calc(100vw - 16px)",
          justifyContent: "space-between",
        }}
      >
        <Box id="chat-header">
          <img
            src={userInfo ? userInfo.urlAvatar : profile}
            style={{
              height: "50px",
              aspectRatio: "1/1",
              borderRadius: "50%",
              border: "1px solid var(--white)",
            }}
          />
          <Box
            style={{
              marginLeft: "10px",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="body1"
              className="dm_head_1"
              style={{ marginTop: 0 }}
            >
              {userInfo ? userInfo.name : null}
            </Typography>
            <Typography variant="body1" className="dm_head_2">
              {userInfo ? userInfo.email : null}
            </Typography>
          </Box>
        </Box>
        {/* <Box
          id="analystics-box"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Button id="analystics-button">{process.env.REACT_APP_ANALYTICS_BUTTON}</Button>
        </Box> */}
      </Box>
      <Box
        className="dm"
        id="dapage"
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          overflow: "auto",
          height: "calc(100vh - 250px)",
        }}
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={() => {
            getData(lastDoc);
          }}
          style={{ display: "flex", flexDirection: "column-reverse" }}
          inverse={true}
          scrollableTarget="dapage"
          hasMore={true}
          scrollThreshold={"300px"}
          initialScrollY={0}
        >
          {messages.map((val) => {
            let isMessageGif = val.chatMessage.includes(".gif");
            let isPhoto = val.chatMessage.includes("https://");

            let dateTimeString;
            if (val.sentAt) {
              dateTimeString = `${val.sentAt
                .toDate()
                .toLocaleTimeString("en-IN", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour12: false,
                })}`
                .replaceAll("/", "-")
                .replaceAll(",", "");
            }

            return (
              <Box
                className={
                  val.sentBy === "sender" ? "recivemsgside" : "sendmsgside"
                }
              >
                <Box
                  className={
                    val.sentBy === "sender" ? "recdm_msgbox" : "senddm_msgbox"
                  }
                >
                  {!isMessageGif ? (
                    isPhoto ? (
                      <img
                        alt=""
                        style={{ width: "170px", aspectRatio: "1/1.5" }}
                        src={val.chatMessage}
                        id="msgimg"
                      />
                    ) : (
                      <Typography variant="body1">{val.chatMessage}</Typography>
                    )
                  ) : null}
                  <Typography
                    variant="body2"
                    style={{ fontSize: "10px", marginTop: "5px" }}
                  >
                    {val.sentAt !== null ? dateTimeString : null}
                  </Typography>
                </Box>
                {val.sentBy === "sender" ? (
                  <Typography
                    variant="body2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "var(--white)",
                      fontFamily: "var(--font)",
                      textShadow: "2px 1px 1px var(--black)",
                    }}
                  >
                    {process.env.REACT_APP_DM_CHAT_SPENT_MESSAGE}&nbsp;{" "}
                    {val.noOfGems} &nbsp;&nbsp;
                    <img
                      alt=""
                      src={diamands}
                      style={{ hight: "30px", width: "30px" }}
                    />
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            );
          })}
        </InfiniteScroll>
      </Box>
      <Box
        className="dm_bottom"
        style={{
          position: "fixed",
          bottom: "5px",
          width: "-webkit-fill-available",
        }}
      >
        <TextField
          focused={false}
          value={inputMessage}
          onChange={(e) => setInPutMessage(e.target.value)}
          size="small"
          id="dm_bottm_textfild"
          placeholder={process.env.REACT_APP_TYPE_YOUR_MESSAGE_PLACEHOLDER}
          inputProps={{
            maxLength: parseInt(process.env.REACT_APP_DM_CHAT_TEXT_COUNT),
            width: "80%",
          }}
          style={{ width: "75%" }}
        />
        <Box className="dma">
          <SendIcon onClick={() => handleSendMessages("chat")} />
        </Box>
        <Box className="dmb" onClick={() => fileUpload.current.click()}>
          <img alt="" src={DMOPENGIF} className="dmbimg" />
          <input
            hidden
            accept="image/*"
            ref={fileUpload}
            multiple
            type="file"
            onChange={(e) => handleSendMessages(e)}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ModalSideUserHelpAndSupportDm;
