import React from "react";
import "./Spinners.scss";
const Spinners = () => {
  return (
    <div
      className="spinnerDiv"
      style={{
        position: "fixed",
        top: "-60px",
        height: "109vh",
        width: "100vw",
        zIndex: "5005",
      }}
    >
      <div
        class="lds-spinner"
        style={{
          top: "50%",
          left: "50%",
          position: "absolute",
          zIndex: "5000",
          background: "var(--themeCustomColor)",
          borderRadius: "10px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Spinners;
