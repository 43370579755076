import React, { useEffect, useState } from "react";
import CallEndIcon from "@mui/icons-material/CallEnd";
import { Call } from "@mui/icons-material";
import { Box } from "@mui/material";
import "./IncomingVideoCallUserSide.scss";
// import ModalProfilePic from "../../../assests/ic_logo.jpg";
import ModalProfilePic from "../../../assests/modelprofilepic.png";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, onSnapshot, query, updateDoc } from "firebase/firestore";
import { db } from "../../../firebaseConfig/firebaseConfig";
import { toast } from "react-toastify";
const IncomingVideoCallUserSide = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [videoCallData, setVideoCallData] = useState({});

  const handleCallReject = () => {
    updateDoc(
      doc(
        db,
        process.env.REACT_APP_CALL_COLLECTION_NAME,
        location.state.videocallobject.docId
      ),
      {
        rejected_by_user: true,
      }
    ).then(() => {
      navigate("/mainback/uservideocall", {
        state: { muted: true },
        replace: true,
      });
    });
  };

  const handleUserJoinToCall = () => {
    updateDoc(
      doc(
        db,
        process.env.REACT_APP_CALL_COLLECTION_NAME,
        location.state.videocallobject.docId
      ),
      {
        has_user_join: true,
      }
    ).then(() => {
      navigate("/oneononevideocallscreen", {
        state: { videocallobject: location.state.videocallobject },
        replace: true,
      });
    });
  };
  const notify = () =>
    toast(process.env.REACT_APP_VIDEO_CALL_END_TOAST_TEXT, { toastId: 1 });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const q1 = query(
      doc(
        db,
        process.env.REACT_APP_CALL_COLLECTION_NAME,
        location.state.videocallobject?.docId
      )
    );
    onSnapshot(q1, (querySnapshot) => {
      setVideoCallData({ ...querySnapshot.data() });
    });
  };
  if (videoCallData?.call_end || videoCallData?.rejected_by_user) {
    notify();
    navigate("/mainback/uservideocall", {
      replace: true,
      state: { muted: false },
    });
  }
  return (
    <Box className="video-call-main-page">
      <img src={ModalProfilePic} className="call-image " />
      <Box className="video-call-screen">
        <CallEndIcon
          className="call-icon input-field-shadow"
          style={{ backgroundColor: "var(--red)" }}
          onClick={() => handleCallReject()}
        />
        <Call
          className="call-icon input-field-shadow"
          style={{ backgroundColor: "var(--green)" }}
          onClick={() => handleUserJoinToCall()}
        />
      </Box>
    </Box>
  );
};

export default IncomingVideoCallUserSide;
