import { Box, Button, Grid, Typography } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";
import logo from "../../../assests/app_logo.png";
import jems from "../../../component/Images/jems.png";
import "../MainHead/Mainhead.scss";
import { Link, Outlet, useNavigate } from "react-router-dom";
function Back_S_head(props) {
  const navigate = useNavigate();
  // window.scrollTo(0, document.body.scrollTop);
  return (
    <Box className="hh">
      <Box className="header">
        <Grid container className="headbox">
          <Grid item xs={1.5}>
            <Box className="menubtn">
              <ArrowBackIcon
                style={{ color: "white" }}
                onClick={() => (props?.close ? props.close() : navigate(-1))}
              />
            </Box>
          </Grid>
          <Grid item xs={8.5}>
            <img alt="" src={logo} className="modalimg" />
          </Grid>
        </Grid>
        <Box className="cmline"></Box>
      </Box>
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
}

export default Back_S_head;
