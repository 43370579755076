import React from "react";
import barbie_girl from "../assests/gifs/barbie_girl.jpg";
import love_your_nails from "../assests/gifs/love_your_nails.jpg";
import hugs_to_you from "../assests/gifs/hugs_to_you.jpg";
import marry_me from "../assests/gifs/marry_me.jpg";
import snow_while from "../assests/gifs/snow_while.jpg";
import roses_for_you from "../assests/gifs/roses_for_you.jpg";
import your_boss_laddy from "../assests/gifs/your_boss_laddy.jpg";
import you_do_best from "../assests/gifs/you_do_best.jpg";
import i_like_you from "../assests/gifs/i_like_you.jpg";
import god_bless_you from "../assests/gifs/god_bless_you.jpg";
import perfect_glass_figure from "../assests/gifs/perfect_glass_figure.jpg";
import i_love_you from "../assests/gifs/i_love_you.jpg";
import my_baby_doll from "../assests/gifs/my_baby_doll.jpg";
import sexiest_girl from "../assests/gifs/sexiest_girl.jpg";
import you_doll from "../assests/gifs/you_doll.jpg";
import once_step_for_me from "../assests/gifs/once_step_for_me.jpg";
import give_me_hug from "../assests/gifs/give_me_hug.jpg";
import collar_bones from "../assests/gifs/collar_bones.jpg";
import flower_for_you from "../assests/gifs/flower_for_you.jpg";
import peaches_and_milk from "../assests/gifs/peaches_and_milk.jpg";
import skin_like_butter from "../assests/gifs/skin_like_butter.jpg";
import creamy_complexion from "../assests/gifs/creamy_complexion.jpg";
import perfume_for_you from "../assests/gifs/perfume_for_you.jpg";
import neckace_for_you from "../assests/gifs/neckace_for_you.jpg";
import wink_me from "../assests/gifs/barbie_girl.jpg";
import sexy_bitch from "../assests/gifs/sexy_bitch.jpg";
import diamond_ring from "../assests/gifs/diamond_ring.jpg";
import sexiest_underarms_ever from "../assests/gifs/sexiest_underarms_ever.jpg";
import your_skin_goreous from "../assests/gifs/your_skin_goreous.jpg";
import barbie_doll from "../assests/gifs/barbie_doll.jpg";
import blow_kiss from "../assests/gifs/blow_kiss.jpg";
import love_your_hovel from "../assests/gifs/love_your_hovel.jpg";
import kiss_for_you from "../assests/gifs/kiss_for_you.jpg";
import be_my_girlfriend from "../assests/gifs/be_my_girlfriend.jpg";
import you_look_stunning from "../assests/gifs/you_look_stunning.jpg";
import preety_dress from "../assests/gifs/preety_dress.jpg";
import love_your_look from "../assests/gifs/love_your_look.jpg";
import i_am_here from "../assests/gifs/i_am_here.jpg";
import sing_a_song from "../assests/gifs/sing_a_song.jpg";
import dare from "../assests/gifs/dare.jpg";
import truth from "../assests/gifs/truth.jpg";
import no from "../assests/gifs/no.jpg";
import yes from "../assests/gifs/yes.jpg";
import lovely_vocie from "../assests/gifs/lovely_vocie.jpg";
import beautiful_moves from "../assests/gifs/beautiful_moves.jpg";
import wear_earrings from "../assests/gifs/wear_earrings.jpg";
import apply_lipstick from "../assests/gifs/apply_lipstick.jpg";
import smile_for_me from "../assests/gifs/smile_for_me.jpg";
import thumbsup from "../assests/gifs/thumbsup.jpg";
import hi_babe from "../assests/gifs/hi_babe.jpg";
import hello_gorgeous from "../assests/gifs/hello_gorgeous.jpg";
import wear_saree from "../assests/gifs/wear_saree.jpg";
import sexy from "../assests/gifs/sexy.jpg";
import want_to_meet_you from "../assests/gifs/want_to_meet_you.jpg";
import coffee_meet from "../assests/gifs/coffee_meet.jpg";
import lunch_meet from "../assests/gifs/lunch_meet.jpg";
import stunning_eyes from "../assests/gifs/stunning_eyes.jpg";
import gorgeous_hands from "../assests/gifs/gorgeous_hands.jpg";
import show_me_your_feet from "../assests/gifs/show_me_your_feet.jpg";
import sexiest_lips from "../assests/gifs/sexiest_lips.jpg";
import beautiful_nails from "../assests/gifs/beautiful_nails.jpg";
import beautiful_hair from "../assests/gifs/beautiful_hair.jpg";
import gorgeous_feets from "../assests/gifs/gorgeous_feets.jpg";
import video_call_with_me_1 from "../assests/gifs/video_call_with_me_1.jpg";
import video_call_with_me_2 from "../assests/gifs/video_call_with_me_2.jpg";
import indian_kim_kardashian from "../assests/gifs/indian_kim_kardashian.jpg";
import spicy from "../assests/gifs/spicy.jpg";
import hottest_navel from "../assests/gifs/hottest_navel.jpg";
import sensational_beauty from "../assests/gifs/sensational_beauty.jpg";
import miss_rita from "../assests/gifs/miss_rita.jpg";
import star from "../assests/gifs/star.jpg";
import beauty_with_brains from "../assests/gifs/beauty_with_brains.jpg";
import say_my_name from "../assests/gifs/say_my_name.jpg";
import sexiest_underarms_ever1 from "../assests/gifs/sexiest_underarms_ever1.jpg";
import pout_for_me from "../assests/gifs/pout_for_me.jpg";
import baby_skin from "../assests/gifs/baby_skin.jpg";
import hottieee from "../assests/gifs/hottieee.jpg";
import queen_of_heart from "../assests/gifs/queen_of_heart.jpg";
import tu_karna_chahti_grind from "../assests/gifs/tu_karna_chahti_grind.jpg";
import you_are_diva from "../assests/gifs/you_are_diva.jpg";
import bindu_re_bindu from "../assests/gifs/bindu_re_bindu.jpg";
import hot_as_hell from "../assests/gifs/hot_as_hell.jpg";
import dream_girl from "../assests/gifs/dream_girl.jpg";
import meet_me from "../assests/gifs/meet_me.jpg";
import mermaid from "../assests/gifs/mermaid.jpg";
import glamour_girl from "../assests/gifs/glamour_girl.jpg";
import jaadu_teri_nazar from "../assests/gifs/jaadu_teri_nazar.jpg";
import gorgeous_smile from "../assests/gifs/gorgeous_smile.jpg";
import kenisha_king from "../assests/gifs/kenisha_king.jpg";
import beautiful_cheeks from "../assests/gifs/beautiful_cheeks.jpg";
import bite_your_lip_sensually from "../assests/gifs/bite_your_lip_sensually.jpg";
import freeze from "../assests/gifs/freeze.jpg";
import hottest_cleavage from "../assests/gifs/hottest_cleavage.jpg";
import indian_kim_kardashian_1 from "../assests/gifs/indian_kim_kardashian_1.jpg";
import indian_kylie_jenner from "../assests/gifs/indian_kylie_jenner.jpg";
import release from "../assests/gifs/release.jpg";
import sexiest_naval from "../assests/gifs/sexiest_naval.jpg";
import show_me_sexy_expressions from "../assests/gifs/show_me_sexy_expressions.jpg";
import show_me_your_back from "../assests/gifs/show_me_your_back.jpg";
import spank from "../assests/gifs/spank.jpg";
import sexiest_lips_1 from "../assests/gifs/sexiest_lips_1.jpg";

export const GifsData = [
  {
    gif_id: 1,
    noOfGems: 199,
    name: "barbie_girl.jpg",
    image: barbie_girl,
  },
  {
    gif_id: 2,
    noOfGems: 199,
    name: "love_your_nails.jpg",
    image: love_your_nails,
  },
  {
    gif_id: 3,
    noOfGems: 499,
    name: "hugs_to_you.jpg",
    image: hugs_to_you,
  },
  {
    gif_id: 4,
    noOfGems: 999,
    name: "marry_me.jpg",
    image: marry_me,
  },
  {
    gif_id: 5,
    noOfGems: 199,
    name: "snow_while.jpg",
    image: snow_while,
  },
  {
    gif_id: 6,
    noOfGems: 299,
    name: "roses_for_you.jpg",
    image: roses_for_you,
  },
  {
    gif_id: 7,
    noOfGems: 499,
    name: "your_boss_laddy.jpg",
    image: your_boss_laddy,
  },
  {
    gif_id: 8,
    noOfGems: 99,
    name: "you_do_best.jpg",
    image: you_do_best,
  },
  {
    gif_id: 9,
    noOfGems: 99,
    name: "i_like_you.jpg",
    image: i_like_you,
  },
  {
    gif_id: 10,
    noOfGems: 199,
    name: "god_bless_you.jpg",
    image: god_bless_you,
  },
  {
    gif_id: 11,
    noOfGems: 299,
    name: "perfect_glass_figure.jpg",
    image: perfect_glass_figure,
  },
  {
    gif_id: 12,
    noOfGems: 499,
    name: "i_love_you.jpg",
    image: i_love_you,
  },
  {
    gif_id: 13,
    noOfGems: 99,
    name: "my_baby_doll.jpg",
    image: my_baby_doll,
  },
  {
    gif_id: 14,
    noOfGems: 399,
    name: "sexiest_girl.jpg",
    image: sexiest_girl,
  },
  {
    gif_id: 15,
    noOfGems: 199,
    name: "you_doll.jpg",
    image: you_doll,
  },
  {
    gif_id: 16,
    noOfGems: 299,
    name: "once_step_for_me.jpg",
    image: once_step_for_me,
  },
  {
    gif_id: 17,
    noOfGems: 499,
    name: "give_me_hug.jpg",
    image: give_me_hug,
  },
  {
    gif_id: 18,
    noOfGems: 199,
    name: "collar_bones.jpg",
    image: collar_bones,
  },
  {
    gif_id: 19,
    noOfGems: 199,
    name: "flower_for_you.jpg",
    image: flower_for_you,
  },
  {
    gif_id: 20,
    noOfGems: 99,
    name: "peaches_and_milk.jpg",
    image: peaches_and_milk,
  },
  {
    gif_id: 21,
    noOfGems: 69,
    name: "skin_like_butter.jpg",
    image: skin_like_butter,
  },
  {
    gif_id: 22,
    noOfGems: 99,
    name: "creamy_complexion.jpg",
    image: creamy_complexion,
  },
  {
    gif_id: 23,
    noOfGems: 199,
    name: "perfume_for_you.jpg",
    image: perfume_for_you,
  },
  {
    gif_id: 24,
    noOfGems: 399,
    name: "neckace_for_you.jpg",
    image: neckace_for_you,
  },
  {
    gif_id: 25,
    noOfGems: 299,
    name: "wink_me.jpg",
    image: wink_me,
  },
  {
    gif_id: 26,
    noOfGems: 499,
    name: "sexy_bitch.jpg",
    image: sexy_bitch,
  },
  {
    gif_id: 27,
    noOfGems: 999,
    name: "diamond_ring.jpg",
    image: diamond_ring,
  },
  {
    gif_id: 28,
    noOfGems: 299,
    name: "sexiest_underarms_ever.jpg",
    image: sexiest_underarms_ever,
  },
  {
    gif_id: 29,
    noOfGems: 399,
    name: "your_skin_goreous.jpg",
    image: your_skin_goreous,
  },
  {
    gif_id: 30,
    noOfGems: 199,
    name: "barbie_doll.jpg",
    image: barbie_doll,
  },
  {
    gif_id: 31,
    noOfGems: 99,
    name: "blow_kiss.jpg",
    image: blow_kiss,
  },
  {
    gif_id: 32,
    noOfGems: 69,
    name: "love_your_hovel.jpg",
    image: love_your_hovel,
  },
  {
    gif_id: 33,
    noOfGems: 99,
    name: "kiss_for_you.jpg",
    image: kiss_for_you,
  },
  {
    gif_id: 34,
    noOfGems: 299,
    name: "be_my_girlfriend.jpg",
    image: be_my_girlfriend,
  },
  {
    gif_id: 35,
    noOfGems: 199,
    name: "you_look_stunning.jpg",
    image: you_look_stunning,
  },
  {
    gif_id: 36,
    noOfGems: 39,
    name: "preety_dress.jpg",
    image: preety_dress,
  },
  {
    gif_id: 37,
    noOfGems: 99,
    name: "love_your_look.jpg",
    image: love_your_look,
  },
  {
    gif_id: 38,
    noOfGems: 39,
    name: "i_am_here.jpg",
    image: i_am_here,
  },
  {
    gif_id: 39,
    noOfGems: 99,
    name: "sing_a_song.jpg",
    image: sing_a_song,
  },
  {
    gif_id: 40,
    noOfGems: 39,
    name: "dare.jpg",
    image: dare,
  },
  {
    gif_id: 41,
    noOfGems: 39,
    name: "truth.jpg",
    image: truth,
  },
  {
    gif_id: 42,
    noOfGems: 39,
    name: "no.jpg",
    image: no,
  },
  {
    gif_id: 43,
    noOfGems: 39,
    name: "yes.jpg",
    image: yes,
  },
  {
    gif_id: 44,
    noOfGems: 39,
    name: "lovely_vocie.jpg",
    image: lovely_vocie,
  },
  {
    gif_id: 45,
    noOfGems: 69,
    name: "beautiful_moves.jpg",
    image: beautiful_moves,
  },
  {
    gif_id: 46,
    noOfGems: 99,
    name: "wear_earrings.jpg",
    image: wear_earrings,
  },
  {
    gif_id: 47,
    noOfGems: 99,
    name: "wear_bindi.jpg",
    image: love_your_nails,
  },
  {
    gif_id: 48,
    noOfGems: 69,
    name: "apply_lipstick.jpg",
    image: apply_lipstick,
  },
  {
    gif_id: 49,
    noOfGems: 39,
    name: "smile_for_me.jpg",
    image: smile_for_me,
  },
  {
    gif_id: 50,
    noOfGems: 39,
    name: "thumbsup.jpg",
    image: thumbsup,
  },
  {
    gif_id: 51,
    noOfGems: 39,
    name: "hi_babe.jpg",
    image: hi_babe,
  },
  {
    gif_id: 52,
    noOfGems: 39,
    name: "hello_gorgeous.jpg",
    image: hello_gorgeous,
  },
  {
    gif_id: 53,
    noOfGems: 249,
    name: "wear_saree.jpg",
    image: wear_saree,
  },
  {
    gif_id: 54,
    noOfGems: 199,
    name: "sexy.jpg",
    image: sexy,
  },
  {
    gif_id: 55,
    noOfGems: 999,
    name: "want_to_meet_you.jpg",
    image: want_to_meet_you,
  },
  {
    gif_id: 56,
    noOfGems: 425000,
    name: "coffee_meet.jpg",
    image: coffee_meet,
  },
  {
    gif_id: 57,
    noOfGems: 425000,
    name: "lunch_meet.jpg",
    image: lunch_meet,
  },
  {
    gif_id: 58,
    noOfGems: 399,
    name: "stunning_eyes.jpg",
    image: stunning_eyes,
  },
  {
    gif_id: 59,
    noOfGems: 299,
    name: "gorgeous_hands.jpg",
    image: gorgeous_hands,
  },
  {
    gif_id: 60,
    noOfGems: 699,
    name: "show_me_your_feet.jpg",
    image: show_me_your_feet,
  },
  {
    gif_id: 61,
    noOfGems: 399,
    name: "sexiest_lips.jpg",
    image: sexiest_lips,
  },
  {
    gif_id: 62,
    noOfGems: 299,
    name: "beautiful_nails.jpg",
    image: beautiful_nails,
  },
  {
    gif_id: 63,
    noOfGems: 299,
    name: "beautiful_hair.jpg",
    image: beautiful_hair,
  },
  {
    gif_id: 64,
    noOfGems: 299,
    name: "gorgeous_feets.jpg",
    image: gorgeous_feets,
  },
  {
    gif_id: 65,
    noOfGems: 4000,
    name: "video_call_with_me_1.jpg",
    image: video_call_with_me_1,
  },
  {
    gif_id: 66,
    noOfGems: 8000,
    name: "video_call_with_me_2.jpg",
    image: video_call_with_me_2,
  },
  {
    gif_id: 67,
    noOfGems: 4000,
    name: "video_call_with_me_1.jpg",
    image: video_call_with_me_1,
  },
  {
    gif_id: 68,
    noOfGems: 8000,
    name: "video_call_with_me_2.jpg",
    image: video_call_with_me_2,
  },
  {
    gif_id: 69,
    noOfGems: 199,
    name: "indian_kim_kardashian.jpg",
    image: indian_kim_kardashian,
  },
  {
    gif_id: 70,
    noOfGems: 199,
    name: "spicy.jpg",
    image: spicy,
  },
  {
    gif_id: 71,
    noOfGems: 99,
    name: "hottest_navel.jpg",
    image: hottest_navel,
  },
  {
    gif_id: 72,
    noOfGems: 99,
    name: "sensational_beauty.jpg",
    image: sensational_beauty,
  },
  {
    gif_id: 73,
    noOfGems: 199,
    name: "miss_rita.jpg",
    image: miss_rita,
  },
  {
    gif_id: 74,
    noOfGems: 199,
    name: "star.jpg",
    image: star,
  },
  {
    gif_id: 75,
    noOfGems: 149,
    name: "beauty_with_brains.jpg",
    image: beauty_with_brains,
  },
  {
    gif_id: 76,
    noOfGems: 149,
    name: "say_my_name.jpg",
    image: say_my_name,
  },
  {
    gif_id: 77,
    noOfGems: 149,
    name: "sexiest_underarms_ever1.jpg",
    image: sexiest_underarms_ever1,
  },
  {
    gif_id: 78,
    noOfGems: 129,
    name: "pout_for_me.jpg",
    image: pout_for_me,
  },
  {
    gif_id: 79,
    noOfGems: 99,
    name: "baby_skin.jpg",
    image: baby_skin,
  },
  {
    gif_id: 80,
    noOfGems: 99,
    name: "hottieee.jpg",
    image: hottieee,
  },
  {
    gif_id: 81,
    noOfGems: 109,
    name: "queen_of_heart.jpg",
    image: queen_of_heart,
  },
  {
    gif_id: 82,
    noOfGems: 99,
    name: "tu_karna_chahti_grind.jpg",
    image: tu_karna_chahti_grind,
  },
  {
    gif_id: 83,
    noOfGems: 199,
    name: "you_are_diva.jpg",
    image: you_are_diva,
  },
  {
    gif_id: 84,
    noOfGems: 169,
    name: "bindu_re_bindu.jpg",
    image: bindu_re_bindu,
  },
  {
    gif_id: 85,
    noOfGems: 169,
    name: "hot_as_hell.jpg",
    image: hot_as_hell,
  },
  {
    gif_id: 86,
    noOfGems: 149,
    name: "dream_girl.jpg",
    image: dream_girl,
  },
  {
    gif_id: 87,
    noOfGems: 399,
    name: "meet_me.jpg",
    image: meet_me,
  },
  {
    gif_id: 88,
    noOfGems: 149,
    name: "mermaid.jpg",
    image: mermaid,
  },
  {
    gif_id: 89,
    noOfGems: 149,
    name: "glamour_girl.jpg",
    image: glamour_girl,
  },
  {
    gif_id: 90,
    noOfGems: 129,
    name: "jaadu_teri_nazar.jpg",
    image: jaadu_teri_nazar,
  },
  {
    gif_id: 91,
    noOfGems: 109,
    name: "gorgeous_smile.jpg",
    image: gorgeous_smile,
  },
  {
    gif_id: 92,
    noOfGems: 999,
    name: "kenisha_king.jpg",
    image: kenisha_king,
  },
  {
    gif_id: 93,
    noOfGems: 199,
    name: "beautiful_cheeks.jpg",
    image: beautiful_cheeks,
  },
  {
    gif_id: 94,
    noOfGems: 399,
    name: "bite_your_lip_sensually.jpg",
    image: bite_your_lip_sensually,
  },
  {
    gif_id: 95,
    noOfGems: 199,
    name: "freeze.jpg",
    image: freeze,
  },
  {
    gif_id: 96,
    noOfGems: 399,
    name: "hottest_cleavage.jpg",
    image: hottest_cleavage,
  },
  {
    gif_id: 97,
    noOfGems: 99,
    name: "indian_kim_kardashian_1.jpg",
    image: indian_kim_kardashian_1,
  },
  {
    gif_id: 98,
    noOfGems: 99,
    name: "indian_kylie_jenner.jpg",
    image: indian_kylie_jenner,
  },
  {
    gif_id: 99,
    noOfGems: 199,
    name: "release.jpg",
    image: release,
  },
  {
    gif_id: 100,
    noOfGems: 199,
    name: "sexiest_naval.jpg",
    image: sexiest_naval,
  },
  {
    gif_id: 101,
    noOfGems: 499,
    name: "show_me_sexy_expressions.jpg",
    image: show_me_sexy_expressions,
  },
  {
    gif_id: 102,
    noOfGems: 199,
    name: "show_me_your_back.jpg",
    image: show_me_your_back,
  },
  {
    gif_id: 103,
    noOfGems: 499,
    name: "spank.jpg",
    image: spank,
  },
  {
    gif_id: 104,
    noOfGems: 199,
    name: "sexiest_lips_1.jpg",
    image: sexiest_lips_1,
  },
];

export const DisplayImage = ({ src, style }) => (
  <img src={src.image} style={{ ...style }} />
);
