import React, { useEffect, useState } from "react";
import "./HomeFeed.scss";
import Profile_view from "./Profile_view";
import Grid_view from "./Grid_view";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig/firebaseConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinners from "../../../components/Spinner/Spinners";
import { getAuth } from "firebase/auth";

const Photos = ({ currentPage, view }) => {
  const [loading, setLoading] = useState(true);
  const [photo, setPhoto] = useState({});
  const [lastDoc, setLastDoc] = useState(null);
  const getLikedCounters = (photos) => {
    const promises = Object.keys(photos).map((id) => {
      const q1 = query(
        collection(db, process.env.REACT_APP_PHOTOS_COLLECTION, id, "liked")
      );
      return getDocs(q1);
    });
    const promisesComments = Object.keys(photos).map((id) => {
      const q2 = query(
        collection(
          db,
          process.env.REACT_APP_PHOTOS_COMMENTS_COLLECTION,
          id,
          "comments"
        )
      );
      return getDocs(q2);
    });
    Promise.all([...promises, ...promisesComments]).then((values) => {
      values.map((data) => {
        if (!data.empty) {
          const path = data.query.path.split("/");
          const docID = path[1];
          if (path[2] == "comments") {
            photos[docID].commentCounter = data.docs.length;
          } else {
            photos[docID].likedCounter = data.docs.length;
          }
        }
      });
      setPhoto({ ...photo, ...photos });
    });
  };
  const getPhotosData = (lastDoc = null) => {
    let q4;
    if (lastDoc) {
      q4 = query(
        collection(db, process.env.REACT_APP_PHOTOS_COLLECTION),
        orderBy("createdAt", "desc"),
        startAfter(lastDoc),
        limit(15)
      );
    } else {
      q4 = query(
        collection(db, process.env.REACT_APP_PHOTOS_COLLECTION),
        orderBy("createdAt", "desc"),
        limit(15)
      );
    }

    getDocs(q4).then((result) => {
      let photos = {};
      setLastDoc(result.docs[result.docs.length - 1]);
      result.forEach((doc) => {
        photos = {
          ...photos,
          [doc.id]: {
            ...doc.data(),
            id: doc.id,
            likedCounter: 0,
            commentCounter: 0,
            myLiked: false,
          },
        };
      });
      setPhoto({ ...photo, ...photos });
      getLikedCounters(photos);
    });
  };

  useEffect(() => {
    getPhotosData();
    window.scrollTo({ top: 0, behavior: "smooth" });
    setLoading(false);
  }, []);

  return currentPage == "photo" ? (
    loading ? (
      <Spinners />
    ) : view == "profile" ? (
      <Profile_view paginate={getPhotosData} lastDoc={lastDoc} data={photo} />
    ) : (
      <Grid_view paginate={getPhotosData} lastDoc={lastDoc} data={photo} />
    )
  ) : null;
};

export default Photos;
