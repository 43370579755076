import Recharge_Gems from "../../assests/recharge_gems.png";
import Diamands from "../../assests/diamands.png";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Rechargepage.scss";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  getDoc,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import { useNavigate } from "react-router-dom";
import Spinners from "../../components/Spinner/Spinners";

function Rechargepage() {
  const [rechargeArray, setRechargeArray] = useState([]);
  const [loading, setIsLoding] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [wallet, setWallet] = useState();
  const [testUser, setTestUser] = useState(
    JSON.parse(localStorage.getItem("testUser") ?? false)
  );
  useEffect(() => {
    window.scrollTo({ behavior: "smooth" });
    setIsLoding(true);
    const q1 = query(
      collection(
        db,
        process.env.REACT_APP_RECHARGE_GEMS_COLLECTION_NAME,
        process.env.REACT_APP_RECHARGE_GEMS_DOCUMENT_ID,
        process.env.REACT_APP_RECHARGE_GEMS_SUBCOLLECTION_NAME
      ),
      orderBy("price", "asc")
    );
    let array = [];
    getDocs(q1).then((result) => {
      result.forEach((doc) => {
        array.push({ ...doc.data(), paymentObjId: doc.id });
      });
      setRechargeArray(array);
      setIsLoding(false);
    });

    const q2 = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    getDoc(q2).then((response) => {
      //  setSelectedUserWallet(response.data());
      const walletData = response?.data();
      setWallet(walletData);
      // console.log(response.data(), "RESPONSE");
    });
  }, []);

  const handleClick = (val) => {
    setIsLoding(true);
    let accessToken = Math.floor(100000 + Math.random() * 900000);
    let rezarPayPaymentObject = {
      paymentToken: accessToken,
      status: process.env.REACT_APP_RECHARGE_GEMS_STATUS_START,
      uid: user.idUser,
      time: serverTimestamp(),
      prize: val.price,
      gems: val.gems,
      free_gems: val?.free_gems ?? 0,
    };
    addDoc(
      collection(db, process.env.REACT_APP_RECHARGE_GEMS_ADD_COLLECTION_NAME),
      rezarPayPaymentObject
    ).then((result) => {
      let paymentObject = JSON.stringify({
        ...val,
        token: rezarPayPaymentObject.paymentToken,
        platform: process.env.REACT_APP_PLATFORM,
        appId: process.env.REACT_APP_RAZARPAY_APPID,
        username: user.name,
        email: user.email,
        mobile: user.mobile,
        testMode: true,
      });
      paymentObject = btoa(paymentObject);
      setIsLoding(false);
      window.location.href =
        process.env.REACT_APP_RECHARGE_URL.concat(paymentObject);
    });
  };

  const handleTestUserWallet = (val) => {
    setIsLoding(true);
    let updatedWallet = {
      ...wallet,
      balance:
        parseInt(wallet?.balance) +
        parseInt(val.gems) +
        parseInt(val.free_gems),
      totalPurchasedGems:
        parseInt(wallet?.totalPurchasedGems) +
        parseInt(val.gems) +
        parseInt(val.free_gems),
      lastRechargeDate: serverTimestamp(),
    };

    updateDoc(
      doc(db, process.env.REACT_APP_WALLET_COLLECTION_NAME, `${user.idUser}`),
      {
        balance:
          parseInt(wallet?.balance) +
          parseInt(val.gems) +
          parseInt(val.free_gems),
        totalPurchasedGems:
          parseInt(wallet?.totalPurchasedGems) +
          parseInt(val.gems) +
          parseInt(val.free_gems),
        lastRechargeDate: serverTimestamp(),
      }
    ).then(() => {
      localStorage.setItem("wallet", JSON.stringify(updatedWallet));
      setWallet({ ...updatedWallet });
      setIsLoding(false);
    });
  };
  return (
    <Box>
      {loading ? <Spinners /> : null}
      <Box className="rechargebox">
        <img alt="" src={Recharge_Gems} className="rechargeimg" />
      </Box>
      <Box className="rechargeheadingbox">
        <Typography variant="h4" id="rechargeheading">
          Wallet Balance:
        </Typography>
        <Typography variant="h4" id="rechargeheading">
          {wallet?.balance}
        </Typography>
      </Box>
      <Box className="rechargecontentbox">
        {rechargeArray.map((val) => {
          const stateData = {
            price: val.price,
            gems: val.gems,
          };
          return (
            <Box
              className="rechargecontent"
              onClick={
                // () =>
                // testUser ? handleTestUserWallet(val) : handleClick(val)
                () => {
                  navigate("/mainback/paymentPage", { state: stateData });
                }
              }
            >
              <Box style={{ display: "flex" }}>
                <img
                  alt=""
                  src={Diamands}
                  style={{ height: "25px", width: "25px", marginRight: "10px" }}
                />
                <Typography variant="body1">
                  {val.gems}
                  {val?.free_gems > 0 ? ` + ${val?.free_gems} free` : null}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1">Rs {val.price}</Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default Rechargepage;
