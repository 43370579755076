import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import jems from "../../component/Images/jems.png";
import "./Photopage.scss";
import { useNavigate } from "react-router-dom";

const ModalforPurchase = (props) => {
  console.log(props, "PROPS");
  const navigate = useNavigate();
  return (
    <Dialog
      open={props.open}
      onClose={() => props.close()}
      className="photopopup"
      aria-labelledby="draggable-dialog-title"
    >
      {props.val.val.noOfGems > props.wallet.balance && (
        <>
          <DialogTitle id="modeltext" className="context-div">
            {process.env.REACT_APP_INSUFFICIENT_BALANCE}
          </DialogTitle>
          <Divider style={{ backgroundColor: "var(--white)" }} />
        </>
      )}

      <DialogContent id="context" style={{ paddingBottom: "0px" }}>
        <DialogContentText className="context-div">
          <Typography variant="h6" id="modeltext">
            {process.env.REACT_APP_WALLET_BALANCE_TEXT}: {props.wallet.balance}
          </Typography>
          <img alt="" src={jems} className="jemsimg" />
        </DialogContentText>
      </DialogContent>
      {props.val.val.noOfGems > props.wallet.balance && (
        <DialogContent id="context">
          <DialogContentText className="context-div">
            <Typography variant="h6" id="modeltext">
              {process.env.REACT_APP_NEED_GEMS_FOR_TEXT}:{" "}
              {props.val.val.noOfGems - props.wallet.balance}
            </Typography>
            <img alt="" src={jems} className="jemsimg" />
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions className="modelbtndiv">
        <Button
          variant="contained"
          onClick={() =>
            props.val.val.noOfGems > props.wallet.balance
              ? window.open(process.env.REACT_APP_DARKMATTER_PAYMENT_LINK)
              : props.handlePurchase(props.val.val)
          }
          id="popbtn"
        >
          {props.val.val.noOfGems > props.wallet.balance
            ? "Recharge"
            : `Buy for ${props.val.val.noOfGems}`}
          <img alt="" src={jems} className="jemsimg" />
        </Button>

        <Button variant="contained" onClick={() => props.close()} id="popbtn">
          {process.env.REACT_APP_MODAL_CANCEL_BUTTON_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalforPurchase;
