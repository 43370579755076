import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./Myprofile.scss";
import profile_icon from "../../component/Images/profile_icon.png";
import { db } from "../../firebaseConfig/firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import Spinners from "../../components/Spinner/Spinners";

function Myprofile() {
  const [data, setData] = useState({
    name: "",
    mobile: "",
    instagramId: "",
    email: "",
    birthdate: "",
    urlAvatar: "",
    userID: "",
  });
  const [userObject, setUserObject] = useState();
  const [editable, setEditable] = useState({ mobile: false, email: false });
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileUpload = useRef();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  useEffect(() => {
    let userObj = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
    );

    setUserObject(userObj);
    setData({
      ...data,
      name: userObj.name,
      mobile: userObj.mobile,
      instagramId: userObj.instagramId,
      email: userObj.email,
      birthdate: userObj.dob,
      urlAvatar: userObj.urlAvatar,
      userID: userObj.idUser,
    });
    // setLoading(true);
    if (userObj.email === "") {
      setEditable({ ...editable, email: true });
    }
    if (userObj.mobile === "+91") {
      setEditable({ ...editable, mobile: true });
    }
  }, [count]);

  const handleSave = () => {
    if (data.name.trim() == "") {
      setText(process.env.REACT_APP_PLEASE_ENTER_NAME_TEXT);
      setOpen(true);
    } else if (data.instagramId.trim() == "") {
      setText(process.env.REACT_APP_PLEASE_ENTER_INSTA_ID_TEXT);
      setOpen(true);
    } else if (data.email.trim() == "") {
      setText(process.env.REACT_APP_ENTER_EMAIL_MODAL_TEXT);
      setOpen(true);
    } else if (
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email) ==
      false
    ) {
      setText(process.env.REACT_APP_INVALID_EMAIL_MODAL_TEXT);
      setOpen(true);
    } else if (data.mobile.trim() == "") {
      setText(process.env.REACT_APP_MOBILE_VALIDATION_TEXT_MODAL);
      setOpen(true);
    } else if (data.mobile.length < 8 || data.mobile.length > 15) {
      setText(
        process.env.REACT_APP_MODILE_NUMBER_VALIDATION_TEXT_FOR_MOBILE_NUMBER
      );
      setOpen(true);
    } else {
      setLoading(true);
      const querySnapshot = doc(
        db,
        process.env.REACT_APP_USERS_COLLECTION_NAME,
        data.userID
      );

      if (file == null) {
        updateDoc(querySnapshot, {
          name: data.name,
          mobile: data.mobile,
          email: data.email,
          instagramId: data.instagramId,
        }).then(() => {
          setOpen(true);
          setText(process.env.REACT_APP_USER_PROFILE_UPDATED_MODAL_TEXT);
          setLoading(false);
        });
      }

      localStorage.setItem(
        process.env.REACT_APP_USERS_LOCALSTORAGE,
        JSON.stringify({
          ...userObject,
          name: data.name,
          mobile: data.mobile,
          email: data.email,
          instagramId: data.instagramId,
        })
      );

      const formData = new FormData();
      formData.append("file", file);

      let axiosConfig = {
        headers: {
          "X-Auth-Email": process.env.REACT_APP_X_AUTH_EMAIL,
          "X-Auth-Key": process.env.REACT_APP_X_AUTH_KEY,
          Authorization: process.env.REACT_APP_AUTHORIZATION,
          "Content-Type": process.env.REACT_APP_CONTENT_TYPE,
          "Access-Control-Allow-Origin":
            process.env.REACT_APP_ACCESS_CONTROL_ALLOW_ORIGIN,
          Accept: process.env.REACT_APP_ACCEPT,
        },
      };
      axios
        .post(
          process.env.REACT_APP_IMAGE_UPLOAD_CLOUDFAIR_URL,
          { file: file },
          axiosConfig
        )
        .then((response) => {
          if (response.data.success) {
            let imgID = response.data.result.id;

            let imagePath = `${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL1}${imgID}${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL2}`;
            localStorage.setItem(
              process.env.REACT_APP_USERS_LOCALSTORAGE,
              JSON.stringify({ ...user, urlAvatar: imagePath })
            );
            updateDoc(querySnapshot, {
              name: data.name,
              mobile: data.mobile,
              email: data.email,
              instagramId: data.instagramId,
              urlAvatar: imagePath,
            }).then(() => {
              setOpen(true);
              setText(process.env.REACT_APP_USER_PROFILE_UPDATED_MODAL_TEXT);
              setCount(count + 1);
              setLoading(false);
            });
          }
        });
    }
  };
  const close = () => {
    setOpen(false);
  };
  const preview = (e) => {
    setFile(e.target.files[0]);
    setData({ ...data, urlAvatar: URL.createObjectURL(e.target.files[0]) });
  };

  return (
    <>
      {loading && <Spinners />}

      <Box className="MyprofileHeadingMain">
        <Box className="MyprofileHeadingdiv">
          <img alt="" src={profile_icon} id="myprofileimg" />
          <Box className="mypvrline" />
          <Typography variant="h6" id="myprofileheading">
            {process.env.REACT_APP_MY_PROFILE_TEXT}
          </Typography>
        </Box>

        <Box className="mypline" />
      </Box>
      <Box className="myprofilemaindiv">
        <Box className="myprofile-uploadpic-div">
          <img alt="" src={data.urlAvatar} id="profilepicimg" />
          <Button
            variant="contained"
            id="myprofilebtn"
            onClick={() => fileUpload.current.click()}
          >
            {process.env.REACT_APP_CHANGE_DP_BUTTON}
            <input
              hidden
              accept="image/*"
              ref={fileUpload}
              multiple
              type="file"
              onChange={(e) => preview(e)}
            />
          </Button>
        </Box>
        <Grid container columnSpacing={1} className="myprofile-form">
          <Grid item xs={4}>
            <Typography variant="body2" id="myprofie-form-categaries">
              {process.env.REACT_APP_NAME_PLACEHOLDER}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box className="myprofileinput">
              <TextField
                type="text"
                placeholder={process.env.REACT_APP_ENTER_NAME_PLACEHOLDER}
                variant="outlined"
                focused={false}
                id="myprofile-form-field"
                fullWidth
                size="small"
                value={data.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" id="myprofie-form-categaries">
              {process.env.REACT_APP_INSTAGRAM_ID_PLACEHOLDER}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box className="myprofileinput">
              <TextField
                type="text"
                placeholder={process.env.REACT_APP_INSTAGRAM_ID_PLACEHOLDER}
                variant="outlined"
                focused={false}
                id="myprofile-form-field"
                fullWidth
                size="small"
                value={data.instagramId}
                onChange={(e) =>
                  setData({ ...data, instagramId: e.target.value })
                }
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" id="myprofie-form-categaries">
              {process.env.REACT_APP_MY_PROFILE_EMAIL_TEXTFIELD}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box className="myprofileinput">
              <TextField
                type="text"
                placeholder={process.env.REACT_APP_ENTER_EMAIL_ID_PLACEHOLDER}
                variant="outlined"
                focused={false}
                id="myprofile-form-field"
                fullWidth
                size="small"
                value={data.email}
                contentEditable={editable.email}
                onChange={
                  editable.email
                    ? (e) => setData({ ...data, email: e.target.value })
                    : ""
                }
                disabled={editable.email ? false : true}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" id="myprofie-form-categaries">
              {process.env.REACT_APP_MOBILE_PLACEHOLDER}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box className="myprofileinput">
              <TextField
                type="text"
                placeholder={
                  process.env.REACT_APP_ENTER_MOBILE_NUMBER_PLACEHOLDER
                }
                variant="outlined"
                focused={false}
                id="myprofile-form-field"
                fullWidth
                size="small"
                value={data.mobile}
                contentEditable={editable.mobile}
                onChange={
                  editable.mobile
                    ? (e) => setData({ ...data, mobile: e.target.value })
                    : ""
                }
                disabled={editable.mobile ? false : true}
                inputProps={{ maxLength: 15 }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" id="myprofie-form-categaries">
              {process.env.REACT_APP_BIRTH_DATE_TITLE}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box className="myprofileinput">
              <TextField
                type="text"
                placeholder={process.env.REACT_APP_ENTER_DOB_PLACEHOLDER}
                variant="outlined"
                focused={false}
                id="myprofile-form-field"
                fullWidth
                value={data.birthdate}
                contentEditable={false}
                size="small"
                disabled={true}
              />
            </Box>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={8}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                padding: "20px 5px",
              }}
            >
              <Button
                variant="contained"
                id="myprofilebtn"
                onClick={handleSave}
              >
                {process.env.REACT_APP_SAVE_CHANGES_BUTTON}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ModalComponent
        close={close}
        open={open}
        text={text}
        title={process.env.REACT_APP_DIALOG_APP_NAME}
      />
    </>
  );
}

export default Myprofile;
