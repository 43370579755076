import React, { useEffect, useState } from "react";
import "./SendRequestForVideocall.scss";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import diamond from "../../../assests/diamands.png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  onSnapshot,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig/firebaseConfig";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import jems from "../../../component/Images/jems.png";

function SendRequestForVideocall() {
  const location = useLocation();
  const navigate = useNavigate();
  const [insufficientBalance, setInsufficientBalance] = useState({
    open: false,
    requiredGems: 0,
  });
  const [purchaseModal, setPurchaseModal] = useState({ open: false, val: "" });
  const [opencal, setOpencal] = useState(false);
  const [openSpecialRequestModal, setOpenSpecialRequestModal] = useState(false);
  const [data, setData] = useState({
    date: dayjs(),
    time: dayjs(),
    checked: false,
    message: "",
  });
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [sh, setSh] = useState(false);
  const [text, setText] = useState("");
  const [wallet, setWallet] = useState();
  const [testUser, setTestUser] = useState(
    JSON.parse(localStorage.getItem("testUser") ?? false)
  );

  useEffect(() => {
    const q = query(
      collection(db, process.env.REACT_APP_WALLET_COLLECTION_NAME),
      where(
        "uid",
        "==",
        JSON.parse(
          localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
        ).idUser
      )
    );
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setWallet(doc.data());
        localStorage.setItem(
          process.env.REACT_APP_WALLET_LOCALSTORAGE,
          JSON.stringify(doc.data())
        );
      });
    });
  }, []);

  const handleClose = () => {
    setOpenSpecialRequestModal(false);
  };
  const handleVideoCallRequest = () => {
    let totalGems =
      parseInt(location.state.gemsForCall) +
      (data.message !== "" ? location.state.specialRequestGems : parseInt(0));
    const q2 = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    getDoc(q2).then((response) => {
      //  setSelectedUserWallet(response.data());
      const walletData = response?.data().balance;

      if (walletData < parseInt(totalGems)) {
        // console.log("INSUFFICIANT");
        setInsufficientBalance({
          open: true,
          requiredGems: parseInt(totalGems),
        });
      } else if (data.checked && data.message.trim() == "") {
        // console.log("BLANKED AND TRIM");
        setText("Please enter special dress request.");

        setOpenSpecialRequestModal(true);
      } else if (wallet?.balance >= parseInt(totalGems)) {
        // console.log("SUCCESS");
        setPurchaseModal({ open: true, val: totalGems });
      }

      //  setWallet(walletData);
      // console.log(response.data().balance, "RESPONSE");
    });
    console.log(wallet, "Wallet Balance");

    // if (data.checked && data.message.trim() == "") {
    // } else if (wallet?.balance < parseInt(totalGems)) {
    // } else if (wallet?.balance >= parseInt(totalGems)) {
    //   setPurchaseModal({ open: true, val: totalGems });
    // }
  };

  const handlePurchaseModal = (totalGems) => {
    const q2 = query(
      collection(db, process.env.REACT_APP_USERS_COLLECTION_NAME),
      where("idUser", "==", wallet?.uid)
    );

    getDocs(q2).then((result) => {
      console.log(result, "REsult");
      result.forEach((users) => {
        if (users.data()?.blocked) {
          console.log("TRUE");
          // navigate("/login");
          navigate("/login");
          localStorage.clear();
        } else {
          setPurchaseModal({ open: false, val: purchaseModal.val });
          let updatebalanceofuser =
            parseInt(wallet?.balance) - parseInt(totalGems);
          updateDoc(
            doc(db, process.env.REACT_APP_WALLET_COLLECTION_NAME, user.idUser),
            { balance: updatebalanceofuser }
          );
          let videoCallRequest = {
            date: data.date.format("MMMM DD,YYYY"),
            email: user.email,
            mins: parseInt(location.state.callDuration),
            name: user.name,
            noOfGems: parseInt(totalGems),
            phone: user?.mobile || "+91 ",
            platform: process.env.REACT_APP_PLATFORM,
            requestedAt: serverTimestamp(),
            specialReqMsg: data.message,
            status: false,
            time: data.time.format("hh:mm A"),
            uid: user.idUser,
            userpic: user.urlAvatar,
            version: process.env.REACT_APP_VERSION,
          };
          addDoc(
            collection(
              db,
              process.env.REACT_APP_VIDEO_CALL_REQUEST_COLLECTION_NAME
            ),
            videoCallRequest
          ).then((result) => {
            if (!testUser) {
              let userTransactionnsTable = {
                dm_message_gif: "",
                dm_message_image: "",
                dm_message_text: "",
                live_session_purchase_gif_name: "",
                live_session_purchase_comment_message: "",
                live_session_ref_id: "",
                platform: process.env.REACT_APP_PLATFORM,
                purchased_media_description: "",
                purchased_media_title: "",
                purchased_media_url: "",
                purchased_product_description: "",
                purchased_product_title: "",
                recharge_gems_transaction_amount: "",
                recharge_gems_transaction_id: "",
                transaction_gems: parseInt(totalGems),
                transaction_gems_string: `-${totalGems}`,
                transaction_ref_id: result.id,
                transaction_time: serverTimestamp(),
                transaction_type_id: "8",
                transaction_type_name: "video_call_request",
                updated_balance_after_transaction: `${updatebalanceofuser}`,
                user_email: user.email,
                user_id: user.idUser,
                user_name: user.name,
                version: process.env.REACT_APP_VERSION,
              };
              addDoc(
                collection(
                  db,
                  process.env.REACT_APP_USERTRANSACTIONS_COLLECTION
                ),
                userTransactionnsTable
              ).then(() => {
                setPurchaseModal({ open: false, val: "" });
                navigate("/mainback/uservideocall", {
                  state: { muted: false },
                });
              });
            } else {
              setPurchaseModal({ open: false, val: "" });
              navigate("/mainback/uservideocall", {
                state: { muted: false },
              });
            }
          });
        }
      });
    });
  };
  return (
    <Box className="srv">
      <Typography variant="body1" className="typography-srv">
        {process.env.REACT_APP_SENT_REQUEST_FOR_VIDEO_CALL}
      </Typography>
      <Box className="cmline" />
      <Box className="srv2">
        <Typography variant="body2" className="typography-srv">
          {process.env.REACT_APP_SENT_REQUEST_FOR_VIDEO_CALL_FOR_MINUTES.replace(
            "5",
            location.state.callDuration
          )}
        </Typography>
        <Typography variant="body2" className="typography-srv">
          {process.env.REACT_APP_SENT_REQUEST_FOR_VIDEO_CALL_SELECT_DATE}
        </Typography>
        <Button
          variant="contained"
          className="srvbtn"
          onClick={() => setOpencal(!opencal)}
        >
          {data.date.format("MMM DD,YYYY")}
        </Button>
        {opencal === true ? (
          <LocalizationProvider dateAdapter={AdapterDayjs} id="caldiv">
            <DemoItem>
              <Box className="calhead">
                <Typography variant="body1">
                  {data.date.format("YYYY")}
                </Typography>
                <Typography
                  variant="h4"
                  className="typ-chekgroupsrv"
                  style={{ textAlign: "left" }}
                >
                  {data.date.format("ddd")},{data.date.format("MMM")}
                  {data.date.format("DD")}
                </Typography>
              </Box>
              <DateCalendar
                defaultValue={dayjs()}
                disablePast={true}
                onChange={(e) => {
                  setData({ ...data, date: dayjs(e) });
                }}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button variant="text" onClick={() => setOpencal(!opencal)}>
                  CANCEL
                </Button>
                <Button
                  variant="text"
                  onClick={() => {
                    setOpencal(!opencal);
                  }}
                >
                  OK
                </Button>
              </Box>
            </DemoItem>
          </LocalizationProvider>
        ) : (
          ""
        )}
        <Typography variant="body2" className="typography-srv">
          {process.env.REACT_APP_SENT_REQUEST_FOR_VIDEO_CALL_SELECT_TIME}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileTimePicker
            defaultValue={dayjs()}
            onChange={(e) => {
              setData({ ...data, time: dayjs(e) });
            }}
            sx={{ width: "110px", borderColor: "none" }}
            focused={false}
          />
        </LocalizationProvider>
        <Box className="chekgroupsrv">
          <Box>
            <Typography
              variant="body1"
              className="typography-srv"
              style={{ textAlign: "left" }}
            >
              {
                process.env
                  .REACT_APP_SENT_REQUEST_FOR_VIDEO_CALL_SPECIAL_REQUEST
              }
            </Typography>
            <Typography variant="body1" className="typ-chekgroupsrv">
              {location.state.specialRequestGems}
              <img alt="" src={diamond} style={{ height: "20px" }} />
            </Typography>
          </Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  className="chekbox"
                  onChange={() =>
                    setData({ ...data, checked: !data.checked, message: "" })
                  }
                />
              }
              className="checktext"
            />
          </FormGroup>
        </Box>
        <Box className="srv_text">
          {data.checked ? (
            <TextField
              multiline
              rows={4}
              id="srv-textfield"
              fullWidth
              placeholder={
                process.env.REACT_APP_VIEW_SPECIAL_CALL_REQUEST_PLACEHOLDER
              }
              focused={false}
              onChange={(e) => setData({ ...data, message: e.target.value })}
            />
          ) : (
            " "
          )}
        </Box>
        <Button
          variant="contained"
          className="srvbtn"
          onClick={() => handleVideoCallRequest()}
        >
          {
            process.env
              .REACT_APP_SENT_REQUEST_FOR_VIDEO_CALL_CONFIRM_BUTTON_TEXT
          }
        </Button>
      </Box>
      {openSpecialRequestModal && (
        <ModalComponent
          open={openSpecialRequestModal}
          close={() => handleClose()}
          text={text}
          title={process.env.REACT_APP_DIALOG_APP_NAME}
        />
      )}
      {insufficientBalance.open && (
        <Dialog
          open={insufficientBalance.open}
          onClose={() =>
            setInsufficientBalance({ open: false, requiredGems: 0 })
          }
          className="photopopup"
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="modeltext" className="context-div">
            Insufficient Gems
          </DialogTitle>
          <Divider style={{ backgroundColor: "var(--white)" }} />

          <DialogContent id="context">
            <DialogContentText className="context-div">
              <Typography variant="h6" id="modeltext">
                {process.env.REACT_APP_WALLET_BALANCE_TEXT}: {wallet?.balance}
              </Typography>
              <img alt="" src={jems} className="jemsimg" />
            </DialogContentText>
          </DialogContent>

          <DialogContent id="context">
            <DialogContentText className="context-div">
              <Typography variant="h6" id="modeltext">
                {process.env.REACT_APP_NEED_GEMS_FOR_TEXT}:{" "}
                {parseInt(insufficientBalance.requiredGems) -
                  parseInt(wallet?.balance)}
              </Typography>
              <img alt="" src={jems} className="jemsimg" />
            </DialogContentText>
          </DialogContent>

          <DialogActions className="modelbtndiv">
            <Button
              variant="contained"
              id="popbtn"
              onClick={() =>
                window.open(process.env.REACT_APP_DARKMATTER_PAYMENT_LINK)
              }
            >
              Recharge
              <img alt="" src={jems} className="jemsimg" />
            </Button>

            <Button
              variant="contained"
              onClick={() =>
                setInsufficientBalance({ open: false, requiredGems: 0 })
              }
              id="popbtn"
            >
              {process.env.REACT_APP_MODAL_CANCEL_BUTTON_TEXT}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {purchaseModal.open && (
        <Dialog
          open={purchaseModal.open}
          onClose={() => setPurchaseModal({ open: false, val: "" })}
          className="photopopup"
          aria-labelledby="draggable-dialog-title"
        >
          <DialogContent id="context">
            <DialogContentText className="context-div">
              <Typography variant="h6" id="modeltext">
                {process.env.REACT_APP_WALLET_BALANCE_TEXT}: {wallet?.balance}
              </Typography>
              <img alt="" src={jems} className="jemsimg" />
            </DialogContentText>
          </DialogContent>

          <DialogActions className="modelbtndiv">
            <Button
              variant="contained"
              onClick={() => handlePurchaseModal(purchaseModal.val)}
              id="popbtn"
            >
              {process.env.REACT_APP_BUY_FOR_TEXT} {purchaseModal.val}
              <img alt="" src={jems} className="jemsimg" />
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                setPurchaseModal({ open: false, val: "" });
              }}
              id="popbtn"
            >
              {process.env.REACT_APP_MODAL_CANCEL_BUTTON_TEXT}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default SendRequestForVideocall;
